import React from 'react'
import { IntlProvider } from 'react-intl'
import _ from 'lodash'

import messages_en from '../../assets/data/locales/en/common.json'
import messages_pt from '../../assets/data/locales/pt/common.json'
import recommended_actions_en from '../../assets/data/locales/en/recommended-actions.json'
import recommended_actions_pt from '../../assets/data/locales/pt/recommended-actions.json'
import messages_de from '../../assets/data/locales/de.json'
import messages_fr from '../../assets/data/locales/fr.json'

const flatten = (obj, path = []) =>
  !_.isObject(obj)
    ? { [path.join('.')]: obj }
    : _.reduce(
        obj,
        (cum, next, key) => _.merge(cum, flatten(next, [...path, key])),
        {}
      )

const menu_messages = {
  en: flatten({ ...recommended_actions_en, ...messages_en }),
  de: flatten(messages_de),
  fr: flatten(messages_fr),
  br: flatten({ ...recommended_actions_pt, ...messages_pt }),
}

const Context = React.createContext()

class IntlProviderWrapper extends React.Component {
  state = {
    locale: localStorage['locasty_default_lang']
      ? JSON.parse(localStorage['locasty_default_lang']).locale
      : 'en',
    messages: localStorage['locasty_default_lang']
      ? menu_messages[JSON.parse(localStorage['locasty_default_lang']).locale]
      : menu_messages['en'],
  }

  render() {
    const { children } = this.props
    const { locale, messages } = this.state
    return (
      <Context.Provider
        value={{
          state: this.state,
          switchLanguage: language => {
            localStorage.setItem(
              'locasty_default_lang',
              JSON.stringify({ locale: language })
            )
            this.setState({
              locale: language,
              messages: menu_messages[language],
            })
          },
        }}
      >
        <IntlProvider
          key={locale}
          locale={locale}
          messages={messages}
          defaultLocale="en"
        >
          {children}
        </IntlProvider>
      </Context.Provider>
    )
  }
}

export { IntlProviderWrapper, Context as IntlContext }
